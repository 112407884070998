import { createStore } from 'redux'

const initialState = {
  sidebarShow: false, // Options: 'responsive', true and false
  asideShow: false,
  darkMode: false
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
